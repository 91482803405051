<template>
    <div class="menu">
        <router-link class="menu-item" :to="{ name: 'broker_home' }">
            <font-awesome-icon class="icon" :icon="['fas', 'home']" />
            <div>首頁</div>
        </router-link>
        <router-link class="menu-item" :to="{ name: 'broker_provider_list' }">
            <font-awesome-icon class="icon" :icon="['fas', 'user']" />
            <div>服務商</div>
        </router-link>
        <div class="menu-item cursor-pointer" @click="logout">
            <font-awesome-icon class="icon" :icon="['fas', 'sign-out-alt']" />
            <div>登出</div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "PhoneMenu",
    methods: {
        ...mapActions("userStore", ["logOutUser"]),
        // 登出
        async logout() {
            await this.$api.LogoutBrokerApi();
            this.logOutUser();
            this.$router.push({ name: "login" });
        },
    },
};
</script>

<style lang="scss">
.menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    .menu-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
            font-size: 18px;
            margin-bottom: 0px;
        }
        &.router-link-active {
            color: #ff5733;
            cursor: initial;
            .icon {
                color: #ff5733;
            }
        }
    }
}
</style>
